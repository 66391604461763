/* body{
  overflow: hidden;
} */
.input-range__track--active,
.input-range__slider {
  background: #6B9FFF !important;
  border-color: #6B9FFF !important;
}

.input-range__label {
  font-size: 0.60rem !important;
}

button:focus {
  outline: none;
}

/* Toggle B */
input:checked~.dot {
  transform: translateX(100%);
}